import { ReactNode, useRef } from "react";

import { useTracking } from "react-tracking";

import { getConfig } from "lib/config";
import {
  googleAnalytics,
  GoogleTrackingData,
  initTrackingTelemetry,
} from "lib/telemetry-providers";

//import useErrorHandler from "hooks/use-error-handler";
import { consoleProvider } from "lib/telemetry-providers";
import { useAppContext } from "store/app-context";

const TrackingProvider = ({ children }: { children: ReactNode }) => {
  const context = useAppContext();
  const { configuration } = context;

  const isInitializedRef = useRef(false);
  if (!isInitializedRef.current && configuration) {
    const { tenantGoogleAnalyticsGA4ID, tenantGoogleTagManagerID } = configuration;

    initTrackingTelemetry(tenantGoogleTagManagerID, tenantGoogleAnalyticsGA4ID);
    isInitializedRef.current = true;
  }

  const config = getConfig();
  const ga4Data: GoogleTrackingData = {
    dimensionData: {
      gid: context.session?.generatorID,
      cid: context.metadata?.campaignID,
      sid: context.metadata?.sourceID,
    },
    tenantID: context.session?.tenantID,
    environment: config?.SENTRY_ENVIRONMENT,
  };

  const { Track } = useTracking(ga4Data, {
    dispatch: (data) => {
      consoleProvider.event(data);
      try {
        googleAnalytics.event(data);
      } catch (error) {
        consoleProvider.event({
          errorLevel: "warning",
          error,
          message: "Could not log analytics event.",
          properties: { action: data.action },
        });
      }
    },
  });

  return <Track>{children}</Track>;
};

export default TrackingProvider;

export const TRACKING_EVENTS = {
  //I started with specific events but BV indicated that the funnels used 'page_view'.  I found that this was only the default
  //and that you can include other events in the funnel.  I kept the specific value in an mp_action property.
  HOME_PAGE: "home_page",
  PAGE_VIEW: "page_view",
  FORM_SUBMIT: "form_submit",
  STEP_VIEW: "step_view",
  NEXT_STEP: "next_step",
  START_OVER: "start_over",
  OFFER_VIEW: "offer_view",
  OFFER_CONSUMED: "offer_consumed",
  ERROR_VIEW: "error_view",
  SRP_SORT: "srp_sort",
  SRP_NUMBER_VEHICLES_RETURNED: "srp_number_vehicles_returned",
};
