import { lazy, Suspense } from "react";

import ScriptLoader from "helpers/script-loader";

import PageWrapper from "hoc/PageWrapper";
import Layout from "layout";
import { Navigate, Route, Routes } from "react-router";

import { useAppContext } from "store/app-context";

import FaviconUpdater from "./FaviconUpdater";

import "lib/query-shadow";
import "./index.scss";

const HomePage = lazy(() => import("pages/home-page"));
const LocationPage = lazy(() => import("pages/location-page"));
const TermsOfUsePage = lazy(() => import("pages/terms-of-use-page"));
const PrivacyPolicyPage = lazy(() => import("pages/privacy-policy-page"));
const SearchResultsPage = lazy(() => import("pages/search-results-page"));
const VehicleDetailPage = lazy(() => import("pages/vehicle-detail-page"));

const AppContent = () => {
  const { configuration } = useAppContext();

  const pageTitle = (page: string) => {
    return `${configuration?.tabTitle} ${page}`;
  };

  const routes = [
    {
      path: "/search-results",
      pageTitle: pageTitle("Search Results"),
      page: <SearchResultsPage pageTitle={pageTitle("Search Results")} />,
    },
    {
      path: "/vehicle-detail",
      pageTitle: pageTitle("Vehicle Detail"),
      page: <VehicleDetailPage pageTitle={pageTitle("Vehicle Detail")} />,
    },
    {
      path: "/location",
      pageTitle: pageTitle("Location"),
      page: <LocationPage pageTitle={pageTitle("Location")} />,
    },
    {
      path: "/terms-of-use",
      pageTitle: pageTitle("Terms of Use"),
      page: <TermsOfUsePage pageTitle={pageTitle("Terms of Use")} />,
    },
    {
      path: "/privacy-policy",
      pageTitle: pageTitle("Privacy Policy"),
      page: <PrivacyPolicyPage pageTitle={pageTitle("Privacy Policy")} />,
    },
    {
      path: "/",
      pageTitle: pageTitle("Homepage"),
      page: <HomePage pageTitle={pageTitle("Homepage")} />,
    },
    { path: "*", pageTitle: "", page: <Navigate to="/" replace={true} /> },
  ];

  return (
    <>
      {configuration?.tenantPixel && (
        <ScriptLoader script={configuration.tenantPixel} id="tenantPixel" />
      )}
      <FaviconUpdater />
      <Routes>
        <Route path="/" element={<Layout />}>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <PageWrapper pageTitle={route.pageTitle}>
                  <Suspense fallback={<></>}>{route.page}</Suspense>
                </PageWrapper>
              }
            />
          ))}
        </Route>
      </Routes>
    </>
  );
};

export default AppContent;
